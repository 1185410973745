var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-img", {
                attrs: {
                  height: "200px",
                  contain: "",
                  src: require("@/assets/images/undraw_taken.svg")
                }
              })
            ],
            1
          ),
          _vm.showTitle
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "technicalIssue" }, [
                  _vm._v(_vm._s(_vm.$t("layout.errorPageTitle")))
                ])
              ])
            : _vm._e(),
          _c("v-col", { attrs: { cols: "8" } }, [
            _c("div", {
              staticClass: "errorMessage",
              domProps: { innerHTML: _vm._s(_vm.message) }
            })
          ]),
          _vm.tryAgain
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.goBack }
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.tryAgain")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }