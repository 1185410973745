<template>
  <v-container fluid fill-height>
    <v-row justify="center">
      <v-col cols="12">
        <v-img
          height="200px"
          contain
          src="@/assets/images/undraw_taken.svg"
        ></v-img
      ></v-col>
      <v-col cols="12" v-if="showTitle">
        <div class="technicalIssue">{{ $t("layout.errorPageTitle") }}</div>
      </v-col>
      <v-col cols="8"><div class="errorMessage" v-html="message"></div></v-col>
      <v-col cols="12" v-if="tryAgain"
        ><v-row justify="center" no-gutters
          ><v-col cols="auto"
            ><v-btn @click="goBack" color="primary">{{
              $t("buttons.tryAgain")
            }}</v-btn></v-col
          ></v-row
        ></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    message: String,
    tryAgain: Boolean
  },
  methods: {
    goBack() {
      window.history.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.technicalIssue {
  padding-top: 63px;
  padding-bottom: 24px;
  font-family: Questrial;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  text-align: center;
  color: #000000;
}
.errorMessage {
  //max-width: 696px;
  font-family: Questrial;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  color: #505050;
  text-align: center;
  .email {
    color: blue;
  }
}
</style>
